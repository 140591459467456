<template>
  <data-form
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm from "../widgets/DataForm.vue";
import UnitsDataService from "../../services/units.service";

export default {
  name: "units-form",
  props: ["data"],
  components: {
    DataForm,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        id: { type: "hidden" },
        code: { label: "Kod", type: "text" },
        name: { label: "Naziv", type: "text" },
        name_en: { label: "Naziv (en)", type: "text" },
        name_cyr: { label: "Naziv (cyr)", type: "text" },
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.updateUnits(newData);
      } else {
        this.createUnits(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    updateUnits(newData) {
      UnitsDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Jedinica mere je promenjena!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    createUnits(newData) {
      UnitsDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Jedinica mere je kreirana!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>