<template>
  <div class="list row">
    <h2>Jedinice mera</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      url="units"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="Units"
    >
    </data-table>
  </div>
</template>

<script>
import UnitsDataService from "../../services/units.service";
import DataTable from "../widgets/DataTable.vue";
import UnitsForm from "./UnitsForm.vue";

export default {
  name: "units",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: ["code", "name", "name_en", "name_cyr", "edit"],
      headers: {
        code: "Kod",
        name: "Naziv",
        name_en: "Naziv (en)",
        name_cyr: "Naziv (cyr)",
        edit: "",
      },
      templates: {},
      actions: {
        edit: { commp: UnitsForm },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: UnitsForm,
        params: { data: {}, title: "Dodaj novu Stopu" },
      });
    },
    deleteData(id) {
      UnitsDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
