import http from "../utils/http-common";

class UnitsDataService {
  async getAll() {
    const resp = await http.get("/units");
    resp.data.rows = resp.data.rows.map((r) => { return { ...r, name: r.name + ` (${r.rate})` } })
    return resp;
  }

  get(id) {
    return http.get(`/units/${id}`);
  }

  create(data) {
    return http.post("/units", data);
  }

  update(id, data) {
    return http.put(`/units/${id}`, data);
  }

  delete(id) {
    return http.delete(`/units/${id}`);
  }
}

export default new UnitsDataService();